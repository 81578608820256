
<template>
	<ClientOnly>
		<!--DevOnly>
			<Vpc />
		</DevOnly-->
		<UtilityConsoleOverlay />
	</ClientOnly>
	<div>
		<slot id="nuxt--content" />
	</div>
</template>

<script>
// import Vpc from "../components/utility/vite-plugin-checker.vue"

// // eigenes layout für eigenen style
// export default {
// 	components: { Vpc },
// }

</script>


<style lang="scss">
#nuxt--content {
	min-height: 100vh;
}
</style>
